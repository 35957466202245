<app-header></app-header>
<app-navbar></app-navbar>
<!-- START MAIN CONTENT -->
<div class="main_content" style="margin-top: -80px;">

    <!-- START SECTION SHOP -->
    <div class="section">
        <div class="container" style="margin-top: -40px;">
            <div class="row mb-3">
                <div class="col-12 mt-5">
                    <a [ngStyle]="{'color': colorTheme}" id="back1" class="btn-click" *ngIf="actual_step === 1" [routerLink]="['', 'carrito']" routerLinkActive="router-link-active"  style="cursor: pointer;"><b><i class="mr-2 fa fa-chevron-left"></i> Regresar a carrito</b></a>
                    <a [ngStyle]="{'color': colorTheme}" id="back2" class="btn-click" *ngIf="actual_step === 2 && purchaseOption === 'account'" [routerLink]="['', 'carrito']" routerLinkActive="router-link-active"  style="cursor: pointer;"><b><i class="mr-2 fa fa-chevron-left"></i> Regresar a carrito</b></a>
                    <a [ngStyle]="{'color': colorTheme}" id="back3" (click)="backStep(1)" class="btn-click" *ngIf="actual_step === 2 && purchaseOption !== 'account'"  style="cursor: pointer;"><b><i class="mr-2 fa fa-chevron-left"></i> Regresar</b></a>
                    <a [ngStyle]="{'color': colorTheme}" id="back4" (click)="backStep(2)" class="btn-click" *ngIf="actual_step === 3 && purchaseOption !== 'account'" style="cursor: pointer;"><b><i class="mr-2 fa fa-chevron-left"></i> Regresar</b></a>
                    <a [ngStyle]="{'color': colorTheme}" id="back4" (click)="backStep(2)" class="btn-click" *ngIf="actual_step === 3 && purchaseOption === 'account' && listOfStores.length > 0" style="cursor: pointer;"><b><i class="mr-2 fa fa-chevron-left"></i> Regresar</b></a>
                    <a [ngStyle]="{'color': colorTheme}" id="back4" class="btn-click" *ngIf="actual_step === 3 && purchaseOption === 'account' && listOfStores.length === 0" style="cursor: pointer;" [routerLink]="['', 'carrito']" routerLinkActive="router-link-active"><b><i class="mr-2 fa fa-chevron-left"></i> Regresar a carrito</b></a>
                    <a [ngStyle]="{'color': colorTheme}" id="back5" (click)="backStep(3)" class="btn-click" *ngIf="actual_step === 4" style="cursor: pointer;"><b><i class="mr-2 fa fa-chevron-left"></i> Regresar</b></a>
                </div>
            </div>
            <!-- <div class="row">
                <div class="col-12">
                    <div class="medium_divider"></div>
                    <div class="divider center_icon"><i class="linearicons-credit-card"></i></div>
                    <div class="medium_divider"></div>
                </div>
            </div> -->
            <div class="row">
                <div class="containter">
                    <div class="col-12 row mt-2"  *ngIf="showAccount">
                        <h4 class="col-12 mb-3">¿Cómo te gustaría realizar tu compra?</h4>
                        <button (click)="selectOption('account', 'Purchase', false)" class="btn btn-click2 col-12 col-md-6 ml-3 mb-2" id="btn1" style="text-transform: none;" [ngStyle]="{'background': colorTheme}"><i class="ti-user mr-2"></i> Con mi cuenta</button>
                        <button (click)="selectOption('guest', 'Purchase', false)" class="btn btn-click2 col-12 col-md-5 ml-3 mb-1" id="btn2"  style="text-transform: none;" [ngStyle]="{'background': colorTheme}"><i class="ti-shopping-cart mr-2"></i> Como invitado</button>
                    </div>
                    <div class="col-12 row mt-2"  *ngIf="showIsHomeDelivery">
                        <h4 class="col-12 mb-3">¿Cómo te gustaría recibir tu compra?</h4>
                        <button (click)="selectOption('home', 'Delivery', false)" class="btn btn-click2 col-12 ml-3 mb-2" [ngClass]="{'col-md-6': listOfStores.length > 0}" id="btn3" style="text-transform: none;" [ngStyle]="{'background': colorTheme}"><i class="ti-user mr-2"></i> Envío a domicilio</button>
                        <button (click)="selectOption('store', 'Delivery', false)" class="btn btn-click2 col-12 col-md-5 ml-3 mb-1" [hidden]="listOfStores.length === 0" id="btn4"  style="text-transform: none;" [ngStyle]="{'background': colorTheme}"><i class="ti-shopping-cart mr-2"></i> Recoger en sucursal</button>
                    </div>
                </div>
                <div class="col-md-6 col-lg-7">
                    <div *ngIf="showDeliveryAddress">
                        <div class="heading_s1">
                            <h4 style="text-transform: none;">Dirección de entrega</h4>
                        </div>
                        <div class="card col-12 mb-3" style="cursor: pointer;" *ngFor="let address of myAddresses; let position = index;" (click)="selectAddress(position)">
                          <div class="card-body">
                            <div class="form-check">
                              <input class="form-check-input" [checked]="address.selected" type="radio" name="flexRadioDefault" id="{{address.id}}">
                              <label class="form-check-label" style="cursor: pointer;" for="{{address.id}}">
                                {{address.address_line}}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="card col-12 mb-3" (click)="addNewAddress()" style="cursor: pointer;" *ngIf="change_customer_address">
                          <div class="card-body pt-3 pb-3">
                            <div class="form-check">
                              <label  class="form-check-label" style="cursor: pointer;"><i class="fa fa-plus mr-2"></i>Agregar dirección</label>
                            </div>
                          </div>
                        </div>
                        <!-- <form method="post">
                            <div class="form-group">
                                <label><span class="text-danger" *ngIf="recibe === '' || recibe === undefined"><i class="fa fa-exclamation-circle"></i></span><span class="text-success" *ngIf="recibe !== '' && recibe !== undefined"><i class="fa fa-check-circle"></i></span> ¿Quien recibirá el pedido?</label>
                                <input type="text" required class="form-control" name="fname" placeholder="Nombre de quién recibe" value="{{recibe}}" (keyup)="escucharValoresInput($event, 'recibe')">
                            </div>
                            <div class="form-group row">
                                <label class="col-12" for="pais"><span class="text-danger" *ngIf="telefono_recibe === '' || telefono_recibe === undefined || telefono_recibe.length < 10"><i class="fa fa-exclamation-circle"></i></span><span class="text-success" *ngIf="telefono_recibe !== '' && telefono_recibe !== undefined && telefono_recibe.length === 10"><i class="fa fa-check-circle"></i></span> Teléfono de contacto</label>
                                <div ngbDropdown class="product_color_switch row col-11 col-md-5 col-xl-3 mr-md-2 pr-1 ml-1">
                                    <button class="btn btn-click3 col-12 text-left" id="phonenumber" (mouseover)="isHover(true, 'phonenumber')" (mouseleave)="isHover(false, 'phonenumber')" ngbDropdownToggle style="padding-left: 10px;padding-right: 5px;text-transform: none;white-space: normal;">{{flagPais}} {{paisSeleccionado}}<i class="fa fa-chevron-down ml-3" style="float: right; margin-top: 2%;"></i></button>
                                    <div ngbDropdownMenu aria-labelledby="phonenumber" style="max-height: 300px;overflow-y: scroll;">
                                        <button class="text-left" ngbDropdownItem *ngFor="let pais of paises ; let positionPais=index" (click)="selectDropdown(pais, 'country')" style="padding-left: 10px;padding-right: 5px;text-transform: uppercase;">{{pais.flag}} {{pais.dial_code}}</button>
                                    </div>
                                </div>
                                <input type="tel" class="form-control col-11 col-md-6 col-xl-8 ml-md-1 ml-3 mt-2 mt-md-0" placeholder="10 dígitos" id="pais" value="{{telefono_recibe}}" (keyup)="escucharValoresInput($event, 'telefono_recibe')">
                            </div>
                            <div class="form-group">
                                <label><span class="text-danger" *ngIf="calle === '' || calle === undefined"><i class="fa fa-exclamation-circle"></i></span><span class="text-success" *ngIf="calle !== '' && calle !== undefined"><i class="fa fa-check-circle"></i></span> Calle</label>
                                <input type="text" class="form-control" name="billing_address" required placeholder="*Calle" value="{{calle}}" (keyup)="escucharValoresInput($event, 'calle')">
                            </div>
                            <div class="form-group">
                                <label><span class="text-danger" *ngIf="no_exterior === '' || no_exterior === undefined"><i class="fa fa-exclamation-circle"></i></span><span class="text-success" *ngIf="no_exterior !== '' && no_exterior !== undefined"><i class="fa fa-check-circle"></i></span> Nº exterior</label>
                                <input type="text" class="form-control" name="billing_address" required placeholder="*Nº exterior" value="{{no_exterior}}" (keyup)="escucharValoresInput($event, 'no_exterior')">
                            </div>
                            <div class="form-group">
                                <label>Nº interior</label>
                                <input type="text" class="form-control" name="billing_address" placeholder="Nº interior" value="{{no_interior}}" (keyup)="escucharValoresInput($event, 'no_interior')">
                            </div>
                            <div class="form-group">
                                <label><span class="text-danger" *ngIf="colonia === '' || colonia === undefined"><i class="fa fa-exclamation-circle"></i></span><span class="text-success" *ngIf="colonia !== '' && colonia !== undefined"><i class="fa fa-check-circle"></i></span> Colonia</label>
                                <input type="text" class="form-control" name="billing_address" placeholder="Colonia" value="{{colonia}}" (keyup)="escucharValoresInput($event, 'colonia')">
                            </div>
                            <div class="form-group">
                                <label><span class="text-danger" *ngIf="municipio === '' || municipio === undefined"><i class="fa fa-exclamation-circle"></i></span><span class="text-success" *ngIf="municipio !== '' && municipio !== undefined"><i class="fa fa-check-circle"></i></span> Municipio</label>
                                <input type="text" class="form-control" name="billing_address" placeholder="Municipio" value="{{municipio}}" (keyup)="escucharValoresInput($event, 'municipio')">
                            </div>
                            <div class="form-group">
                                <label><span class="text-danger" *ngIf="estadoSeleccionado === undefined"><i class="fa fa-exclamation-circle"></i></span><span class="text-success" *ngIf="estadoSeleccionado !== undefined"><i class="fa fa-check-circle"></i></span> Estado</label>
                                <div ngbDropdown class="product_color_switch">
                                    <button *ngIf="estadoSeleccionado === undefined" class="btn btn-click3 col-12 text-left" id="state" (mouseover)="isHover(true, 'state')" (mouseleave)="isHover(false, 'state')" ngbDropdownToggle style="padding-left: 10px;padding-right: 5px;text-transform: none;white-space: normal;">Seleccione un estado<i class="fa fa-chevron-down ml-3" style="float: right; margin-top: 1%;"></i></button>
                                    <button *ngIf="estadoSeleccionado !== undefined" class="btn btn-click3 col-12 text-left" id="state" (mouseover)="isHover(true, 'state')" (mouseleave)="isHover(false, 'state')" ngbDropdownToggle style="padding-left: 10px;padding-right: 5px;text-transform: none;white-space: normal;">{{estadoSeleccionado.nombre}}<i class="fa fa-chevron-down ml-3" style="float: right; margin-top: 1%;"></i></button>
                                    <div ngbDropdownMenu aria-labelledby="state" style="max-height: 300px;overflow-y: scroll;">
                                        <button class="text-left" ngbDropdownItem *ngFor="let estado of estados ; let positionEstado=index" (click)="selectDropdown(estado, 'state')" style="padding-left: 10px;padding-right: 5px;text-transform: uppercase;">{{estado.nombre}}</button>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label><span class="text-danger" *ngIf="postal_code === '' || postal_code === undefined || postal_code.length !== 5"><i class="fa fa-exclamation-circle"></i></span><span class="text-success" *ngIf="postal_code !== '' && postal_code !== undefined && postal_code.length === 5"><i class="fa fa-check-circle"></i></span> Código postal</label>
                                <input type="text" class="form-control" name="billing_address" placeholder="Código postal" value="{{postal_code}}" (keyup)="escucharValoresInput($event, 'postal_code')" maxlength="5">
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" name="billing_address" required="" placeholder="Entre la calle ..." value="{{entre_calle_1}}" (keyup)="escucharValoresInput($event, 'entre_calle_1')">
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" name="billing_address" required="" placeholder="y la calle ..." value="{{entre_calle_2}}" (keyup)="escucharValoresInput($event, 'entre_calle_2')">
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" name="billing_address2" required="" placeholder="Indicaciones extras" value="{{indicaciones_extra}}" (keyup)="escucharValoresInput($event, 'indicaciones_extra')">
                            </div>
                        </form> -->
                        <div class="heading_s1 mt-3">
                            <h6 class="col-12 mb-3" style="text-transform: none;">¿Tienes alguna nota o comentario extra para tu pedido?</h6>
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Agrégalos aquí" value="{{extraComments}}" (keyup)="escucharValoresInput($event, 'extra_comments')" [disabled]="disabledButtonPay && paymentMethodSelected !== undefined">
                            </div>
                        </div>
                    </div>
                    <div *ngIf="showDeliveryShop">
                        <!-- <form>
                            <div class="form-group">
                                <label><span class="text-danger" *ngIf="recibe === '' || recibe === undefined"><i class="fa fa-exclamation-circle"></i></span><span class="text-success" *ngIf="recibe !== '' && recibe !== undefined"><i class="fa fa-check-circle"></i></span> ¿Quien recogerá el pedido?</label>
                                <input type="text" required class="form-control" name="fname" placeholder="Nombre de quién recoge" value="{{recibe}}" (keyup)="escucharValoresInput($event, 'recibe')">
                            </div>
                            <div class="form-group row">
                                <label class="col-12" for="pais"><span class="text-danger" *ngIf="telefono_recibe === '' || telefono_recibe === undefined || telefono_recibe.length < 10"><i class="fa fa-exclamation-circle"></i></span><span class="text-success" *ngIf="telefono_recibe !== '' && telefono_recibe !== undefined && telefono_recibe.length === 10"><i class="fa fa-check-circle"></i></span> Teléfono de contacto</label>
                                <div ngbDropdown class="product_color_switch row col-11 col-md-5 col-xl-3 mr-md-2 pr-1 ml-1">
                                    <button class="btn btn-click3 col-12 text-left" id="phonenumber" (mouseover)="isHover(true, 'phonenumber')" (mouseleave)="isHover(false, 'phonenumber')" ngbDropdownToggle style="padding-left: 10px;padding-right: 5px;text-transform: none;white-space: normal;">{{flagPais}} {{paisSeleccionado}}<i class="fa fa-chevron-down ml-3" style="float: right; margin-top: 2%;"></i></button>
                                    <div ngbDropdownMenu aria-labelledby="phonenumber" style="max-height: 150px;overflow-y: scroll;">
                                        <button class="text-left" ngbDropdownItem *ngFor="let pais of paises ; let positionPais=index" (click)="selectDropdown(pais, 'country')" style="padding-left: 10px;padding-right: 5px;text-transform: uppercase;">{{pais.flag}} {{pais.dial_code}}</button>
                                    </div>
                                </div>
                                <input type="tel" class="form-control col-11 col-md-6 col-xl-8 ml-md-1 ml-3 mt-2 mt-md-0" placeholder="10 dígitos" id="pais" value="{{telefono_recibe}}" (keyup)="escucharValoresInput($event, 'telefono_recibe')">
                            </div>
                        </form> -->
                        <div class="heading_s1">
                            <h5 class="col-12 mb-2 mt-4" style="text-transform: none;">Seleccione un punto de recolección</h5>
                            <button *ngFor="let store of listOfStores; let position = index;" (click)="selectStore(store)" class="btn btn-click3 col-12 text-left mb-2 ml-1" id="store_{{position}}" (mouseover)="isHover(true, 'store_'+position)" (mouseleave)="isHover(false, 'store_'+position)" style="padding-left: 10px;padding-right: 5px;text-transform: none;white-space: normal;"><i class="fa fa-circle ml-3 icon-btn" style="float: right; margin-top: 0%;" *ngIf="store.id !== this.storeSelected"></i><i class="fa fa-check-circle ml-3 text-success" style="float: right; margin-top: 0%;" *ngIf="store.id === this.storeSelected"></i><b style="padding-right:10px">{{store.name}}</b> <br/><b style="padding-right:10px; font-weight: normal;">{{store.address_line}}</b></button>
                        </div>
                        <!-- <div class="heading_s1">
                            <h6 class="col-12 mb-3" style="text-transform: none;">¿Tienes alguna nota o comentario extra para tu pedido?</h6>
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Agrégalos aquí" value="{{extraComments}}" (keyup)="escucharValoresInput($event, 'extra_comments')" [disabled]="disabledButtonPay && paymentMethodSelected !== undefined">
                            </div>
                        </div> -->
                    </div>
                    <div *ngIf="showPaymentMethods">
                        <div class="heading_s1">
                            <h4 class="col-12 mb-3" style="text-transform: none;">¿Cómo te gustaría pagar?</h4>
                            <button *ngFor="let payment of listOfPayments2; let position = index;" (click)="selectPayment(payment)" class="btn btn-click3 col-12 text-left mb-2 ml-1" id="payment{{position}}" (mouseover)="isHover(true, 'payment'+position)" (mouseleave)="isHover(false, 'payment'+position)" style="padding-left: 10px;padding-right: 5px;text-transform: none;white-space: normal;" [disabled]="disabledButtonPay && paymentMethodSelected !== undefined"><i class="fa fa-circle ml-3 icon-btn" style="float: right; margin-top: 0%;" *ngIf="paymentMethodSelected === undefined || payment.id !== this.paymentMethodSelected.id"></i><i class="fa fa-check-circle ml-3 text-success" style="float: right; margin-top: 0%;" *ngIf="paymentMethodSelected !== undefined && payment.id === paymentMethodSelected.id"></i><b style="padding-right:10px; font-weight: normal;">{{payment.title}}</b></button>
                        </div>
                        <div class="heading_s1">
                          <h6 class="col-12 mb-3" style="text-transform: none;">¿Tienes alguna nota o comentario extra para tu pedido?</h6>
                          <div class="form-group">
                              <input type="text" class="form-control" placeholder="Agrégalos aquí" value="{{extraComments}}" (keyup)="escucharValoresInput($event, 'extra_comments')" [disabled]="disabledButtonPay && paymentMethodSelected !== undefined">
                          </div>
                        </div>
                        <!-- <div class="heading_s1">
                            <h6 class="col-12 mb-3" style="text-transform: none;">¿Tienes alguna nota o comentario extra para tu pedido?</h6>
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Agrégalos aquí" value="{{extraComments}}" (keyup)="escucharValoresInput($event, 'extra_comments')" [disabled]="disabledButtonPay && paymentMethodSelected !== undefined">
                            </div>
                        </div> -->
                    </div>
                    <!-- <div class="row" *ngIf="mostrarFacturacion && actual_step >=3">
                      <div class="col-sm-12">
                          <form class="theme-form">
                              <div class="form-row">
                                <h4 class="col-12 mb-3" style="text-transform: none;">Datos de facturación</h4>
                                  <div class="col-12 mb-4">
                                      <label for="name"><span class="text-danger" *ngIf="razon_social === '' || razon_social === undefined"><i class="fa fa-exclamation-circle"></i></span><span class="text-success" *ngIf="razon_social !== '' && razon_social !== undefined"><i class="fa fa-check-circle"></i></span> Nombre o Razon social</label>
                                      <input type="text" class="form-control" id="name" value="{{razon_social}}" (keyup)="escucharValoresInput($event, 'razon_social')">
                                  </div>
                                  <div class="col-md-6 mb-4">
                                      <label for="zip_code"><span class="text-danger" *ngIf="zip_code === '' || zip_code === undefined"><i class="fa fa-exclamation-circle"></i></span><span class="text-success" *ngIf="zip_code !== '' && zip_code !== undefined"><i class="fa fa-check-circle"></i></span> Codigo postal</label>
                                      <input type="text" class="form-control" id="zip_code" value="{{zip_code}}" (keyup)="escucharValoresInput($event, 'zip_code')">
                                  </div>
                                  <div class="col-md-6 mb-2">
                                    <label for="rfc"><span class="text-danger" *ngIf="rfc === '' || rfc === undefined"><i class="fa fa-exclamation-circle"></i></span><span class="text-success" *ngIf="rfc !== '' && rfc !== undefined"><i class="fa fa-check-circle"></i></span> RFC</label>
                                    <input type="text" class="form-control" id="rfc" value="{{rfc}}" (keyup)="escucharValoresInput($event, 'rfc')">
                                  </div>
                                  <div class="col-12 mb-2">
                                    <div class="form-group">
                                        <label><span class="text-danger" *ngIf="regimenSeleccionado === '' || regimenSeleccionado === undefined"><i class="fa fa-exclamation-circle"></i></span><span class="text-success" *ngIf="regimenSeleccionado !== '' && regimenSeleccionado !== undefined"><i class="fa fa-check-circle"></i></span> Régimen fiscal</label>
                                        <div class="custom_select" [formGroup]="regimenForm">
                                            <select class="form-control" [(ngModel)]="regimenSeleccionado" formControlName="regimen">
                                                <option value="">Seleccione un régimen</option>
                                                <option [ngValue]="regimen.clave" *ngFor="let regimen of regimenesFiscales ; let positionEstado=index">{{regimen.nombre}}</option>
                                            </select>
                                        </div>
                                    </div>
                                  </div>
                                  <div class="col-12 mb-4" *ngIf="regimenSeleccionado !== ''">
                                    <div class="form-group">
                                        <label><span class="text-danger" *ngIf="usoCFDISeleccionado === '' || usoCFDISeleccionado === undefined"><i class="fa fa-exclamation-circle"></i></span><span class="text-success" *ngIf="usoCFDISeleccionado !== '' && usoCFDISeleccionado !== undefined"><i class="fa fa-check-circle"></i></span> Uso del CFDI</label>
                                        <div class="custom_select" [formGroup]="usoForm">
                                            <select class="form-control" [(ngModel)]="usoCFDISeleccionado" formControlName="uso">
                                                <option value="">Seleccione uso del CFDI</option>
                                                <option [ngValue]="uso.clave" *ngFor="let uso of usosCFDI; let positionEstado=index">{{uso.nombre}}</option>
                                            </select>
                                        </div>
                                    </div>
                                  </div>
                              </div>
                          </form>
                      </div>
                  </div> -->
                </div>
                <div class="col-md-6 col-lg-5">
                  <div class="col-12 row ml-0 mt-2" style="display: flex;text-align: left;" *ngIf="actual_step >= 3">
                    <p style="text-transform: none;flex: 1;">¿Necesitas factura?</p>
                    <div class="form-check form-switch" style="flex: 1">
                      <input type="checkbox" hidden="hidden" id="need_invoice" [checked]="mostrarFacturacion" (click)="requireInvoice()">
                      <label class="switch" for="need_invoice"></label>
                    </div>
                  </div>
                    <div class="order_review" *ngIf="showOrderReview">
                        <div class="heading_s1">
                            <h4 style="text-transform: none;">Detalle del pedido</h4>
                        </div>
                        <div class="ml-0 col-12" style="margin-top: -15px;">
                            <button (click)="showProducts()" class="btn btn-title-coupon col-12 text-left mb-2" style="padding-left: 10px;padding-right: 5px;text-transform: none;white-space: normal;" [ngStyle]="{'color': colorTheme}"><i class="fa fa-shopping-basket mr-2" *ngIf="!showProductsOnOrder"></i><b *ngIf="!showProductsOnOrder">Ver productos</b><i class="fa fa-chevron-down ml-3" style="float: right; margin-top: 2%;" *ngIf="!showProductsOnOrder"></i><i class="fa fa-chevron-up ml-3" style="float: right; margin-top: 2%;" *ngIf="showProductsOnOrder"></i></button>
                            <div class="row" *ngIf="showProductsOnOrder">
                                <div class="col-1 text-center"></div>
                                <div class="col-6 col-sm-8 col-lg-8 text-center">Producto</div>
                                <div class="col-4 col-sm-2 text-center">Precio</div>
                            </div>
                            <div [hidden]="!showProductsOnOrder" class="row mb-3 mt-2" *ngFor="let product of products">
                                <div class="col-1 text-center" style="vertical-align: middle;">
                                    <span class="product-qty">{{product.quantity}}</span>
                                </div>
                                <div class="col-6 col-sm-8 col-lg-8 text-center">
                                    {{product.title}}
                                    <div class="pr_switch_wrap text-center" *ngIf="product.attribute_types === 'variations'" style="font-size: 12px;">
                                        <span *ngIf="product.variation.variant1.id !== undefined"><b>{{product.variation.variant1.name}}:</b> {{product.variation.variant1.value_name}}</span><br/>
                                        <span *ngIf="product.variation.variant2.id !== undefined"><b>{{product.variation.variant2.name}}:</b> {{product.variation.variant2.value_name}}</span><br *ngIf="product.variation.variant2.id !== undefined"/>
                                    </div>
                                </div>
                                <div class="col-4 col-sm-2 text-center" *ngIf="!product.has_wholesale_price && product.minimum_purchase >=0 && !product.has_discount">
                                    {{product.normal_price | currency}} {{product.currency}}
                                </div>
                                <div class="col-4 col-sm-2 text-center" *ngIf="!product.has_wholesale_price && product.minimum_purchase >=0 && product.has_discount">
                                    {{product.normal_price * ((100 - product.discount)/100) | currency}} {{product.currency}}
                                </div>
                                <div class="col-4 col-sm-2 text-center" *ngIf="product.has_wholesale_price && product.quantity >= product.minimum_purchase && !product.has_discount">
                                    {{product.wholesale_price | currency}} {{product.currency}}
                                </div>
                                <div class="col-4 col-sm-2 text-center" *ngIf="product.has_wholesale_price && product.quantity >= product.minimum_purchase && product.has_discount">
                                    {{product.wholesale_price * ((100 - product.discount)/100) | currency}} {{product.currency}}
                                </div>
                                <div class="col-4 col-sm-2 text-center" *ngIf="product.has_wholesale_price && product.quantity < product.minimum_purchase && !product.has_discount">
                                    {{product.normal_price | currency}} {{product.currency}}
                                </div>
                                <div class="col-4 col-sm-2 text-center" *ngIf="product.has_wholesale_price && product.quantity < product.minimum_purchase && product.has_discount">
                                    {{product.normal_price * ((100 - product.discount)/100) | currency}} {{product.currency}}
                                </div>
                            </div>
                        </div>
                        <hr [ngClass]="{'mt-5': showProductsOnOrder, 'mt-1': !showProductsOnOrder}">
                        <div class="col-12 row" style="margin-bottom: -30px;">
                            <p class="col-6 col-sm-3 col-lg-2 text-center">Subtotal</p>
                            <p class="col-6 col-sm-9 col-lg-10 text-center"><b>{{subtotal | currency}} {{subtotalCurrency}}</b></p>
                        </div>
                        <hr *ngIf="coupon.validation_id !== ''">
                        <div class="col-12 row" style="margin-bottom: -30px;" *ngIf="coupon.validation_id !== ''">
                            <p class="col-6 col-sm-3 col-lg-2 text-center">Descuento</p>
                            <p class="col-6 col-sm-9 col-lg-10 text-center"><b [ngStyle]="{'color': colorTheme}">- {{coupon.amount | currency}} {{subtotalCurrency}}</b></p>
                        </div>
                        <hr>
                        <div class="col-12 row" style="margin-bottom: -30px;">
                            <p class="col-6 col-sm-3 col-lg-2 text-center">Envío</p>
                            <p class="col-6 col-sm-9 col-lg-10 text-center" *ngIf="shippingPrice >= 0 && quotation !== null && deliveryOption === 'home'"><b>{{shippingPrice | currency}} {{subtotalCurrency}}</b></p>
                            <p class="col-6 col-sm-9 col-lg-10 text-center" *ngIf="shippingPrice >= 0 && deliveryOption !== 'home' && storeSelected !== ''"><b>{{shippingPrice | currency}} {{subtotalCurrency}}</b></p>
                            <!-- <p class="col-6 col-sm-9 col-lg-10 text-center" *ngIf="deliveryOption === 'home' && this.postal_code !== undefined && this.postal_code.length < 5"><b>Ingrese código postal</b></p> -->
                            <p class="col-6 col-sm-9 col-lg-10 text-center" *ngIf="deliveryOption === 'home' && quotation === null"><b>Cotizando envío...</b></p>
                            <p class="col-6 col-sm-9 col-lg-10 text-center" *ngIf="deliveryOption === 'home' && shippingPrice === null"><b>Sin cobertura</b></p>
                            <p class="col-6 col-sm-9 col-lg-10 text-center" *ngIf="deliveryOption !== 'home' && shippingPrice === 0 && storeSelected === ''"><b>Seleccione sucursal</b></p>
                        </div>
                        <hr>
                        <div class="col-12 row">
                            <p class="col-6 col-sm-3 col-lg-2 text-center">Total</p>
                            <p class="col-6 col-sm-9 col-lg-10 text-center"><b>{{total | currency}} {{subtotalCurrency}}</b></p>
                        </div>
                        <button id="confirm_order" (click)="confirmarCompra()" *ngIf="actual_step === 3" class="btn btn-click2 text-white btn-block" [ngStyle]="{'background': colorTheme}">CONFIRMAR</button>
                        <button id="pay_order" style="text-transform: none;" (click)="confirmarOrden()" *ngIf="actual_step === 4" class="btn text-white btn-block noshadow pb-3" [ngStyle]="{'background': bgButtonPay}" ejs-progressbutton [enableProgress]="true" content="{{textButton}}" [enableHtmlSanitizer]="true" [animationSettings]="animationSettings" cssClass="e-hide-spinner" (begin)="beginPay()" [disabled]="disabledButtonPay || validatingCoupon"></button>
                    </div>
                    <div class="col-12 row coupon_box ml-0 mt-4" *ngIf="showOrderReview && actual_step === 4 && showOptionCoupon">
                        <button (click)="selectCoupon()" class="btn btn-title-coupon col-12 text-left" style="padding-left: 10px;padding-right: 5px;text-transform: none;white-space: normal;"><i class="fa fa-ticket-alt mr-2" [ngStyle]="{'color': colorTheme}"></i>Cupón de descuento<i class="fa fa-chevron-down ml-3" style="float: right; margin-top: 2%;" *ngIf="!showCoupon"></i><i class="fa fa-chevron-up ml-3" style="float: right; margin-top: 2%;" *ngIf="showCoupon"></i></button>
                        <div class="form-group col-12" *ngIf="showCoupon">
                            <input [ngClass]="{'noselect': disabledButtonCoupon}" type="text" class="form-control col-12 mb-2" placeholder="Ingrese el código" value="{{coupon.code}}" (keyup)="escucharValoresInput($event, 'coupon')" [disabled]="disabledButtonCoupon">
                            <!-- <button class="btn btn-click2 col-12" [disabled]="coupon.code === ''">APLICAR</button> -->
                            <button *ngIf="coupon.validation_id === ''" id="coupon" style="text-transform: none;" (click)="validateCoupon()" class="btn text-white btn-block noshadow pb-3" [ngStyle]="{'background': bgButtonCoupon}" ejs-progressbutton [duration]="durationAnimation2" [enableProgress]="true" content="{{textCoupon}}" [enableHtmlSanitizer]="true" [animationSettings]="animationSettings2" cssClass="e-hide-spinner" (begin)="beginApply()" [disabled]="disabledButtonCoupon || coupon.code === ''"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END SECTION SHOP -->

</div>
<!-- END MAIN CONTENT -->
<app-footer></app-footer>
<ngx-spinner bdColor="{{colorRgb}}" size = "large" color = "#fff" type = "square-jelly-box" [fullScreen] = "true"><p style="color: white;margin-top: 100px;font-size: 200%;"> Colocando pedido... </p></ngx-spinner>
