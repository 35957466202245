<app-header *ngIf="isB2C"></app-header>
<app-navbar *ngIf="isB2C"></app-navbar>
<!-- START MAIN CONTENT -->
<div class="main_content">

    <!-- START LOGIN SECTION -->
    <div class="login_register_wrap section">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-6 col-md-10">
                    <div class="login_wrap">
                        <div class="padding_eight_all bg-white">
                            <div class="heading_s1">
                                <h3 style="text-transform: none;">Iniciar sesión</h3>
                            </div>
                            <form method="post">
                                <div class="form-group">
                                    <input type="text" required="" class="form-control" name="email" placeholder="Correo electrónico" (keyup)="escucharValoresInput($event, 'correo')" (paste)="escucharValoresInput($event, 'correo')">
                                </div>
                                <div class="form-group">
                                    <input class="form-control" required="" type="password" name="password" placeholder="Contraseña" (keyup)="escucharValoresInput($event, 'contrasenia')" (paste)="escucharValoresInput($event, 'contrasenia')">
                                </div>
                                <div class="form-group">
                                    <button type="submit" class="btn btn-block" name="login" (click)="iniciarSesion()" [ngStyle]="{'background': colorTheme, 'color': 'white'}">INGRESAR</button>
                                </div>
                                <div class="form-note text-center form-group">¿Olvidaste tu contraseña? <a [routerLink]="['', 'recuperar']" id="recover" (mouseover)="isHover2(true, 'recover')" (mouseleave)="isHover2(false, 'recover')">Recupérala aquí</a></div>
                            </form>
                            <!-- <hr class="col-11"/> -->
                            <!-- <div class="form-group">
                                <button type="submit" class="btn bg-fb col-12" name="login" (click)="goToFacebookAuth()" style="text-transform: none;">Ingresar con Facebook</button>
                            </div> -->
                            <hr class="col-11"/>
                            <div class="form-note text-center">¿Aún no tienes cuenta? <a [routerLink]="['', 'registrarme']" id="signup" (mouseover)="isHover2(true, 'signup')" (mouseleave)="isHover2(false, 'signup')">Crea una cuenta aquí</a></div>

                        </div>
                    </div>
                    <p class="mt-2 text-center">v{{currentVersion}}</p>
                </div>
            </div>
        </div>
    </div>
    <!-- END LOGIN SECTION -->

</div>
<!-- END MAIN CONTENT -->
<app-footer *ngIf="isB2C"></app-footer>
