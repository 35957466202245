import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalDismissReasons, NgbActiveModal, NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { ProductServiceService } from '../../../services/product/product-service.service';
import { QuickViewComponent } from '../../widgets/quick-view/quick-view.component';
import { CartModalComponent } from '../../widgets/cart-modal/cart-modal.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { RestApiServiceService } from '../../../services/api/rest-api-service.service';
import { Title } from '@angular/platform-browser';
// import { ItemsRequest } from 'src/app/classes/items_request';
// import { ShopsCartAngularService } from '@ignacioruben7/shops-cart-angular';
import { environment } from 'src/environments/environment.prod';
import { StyleManagerService } from '@ignacioruben7/shops-styling-angular';
import { ShopsAuthAngularService } from '@ignacioruben7/shops-auth-angular';
import { ConsumeWorkersService } from '../../../services/workers/consume-workers.service';
// import { PreorderModalComponent } from '../../widgets/preorder-modal/preorder-modal.component';
// import { CartServiceService } from 'src/app/services/cart/cart-service.service';
import { CustomStoreService } from 'src/app/services/store/custom-store.service';

@Component({
  selector: 'app-main-store',
  templateUrl: './main-store.component.html',
  styleUrls: ['./main-store.component.css'],
  styles: [
    `.price {
      color: var(--colorThemePrimary);
    }`,
    `.pr_action_btn li a:hover {
      background-color: var(--colorThemePrimary);
    }`,
    `.pagination_style1 .page-item.active .page-link,
    .pagination_style1 .page-item .page-link:hover {
        background-color: var(--colorThemePrimary);
        border-color: var(--colorThemePrimary);
    }`,
    `.dash-btn-outline-1:hover {
      background: var(--colorThemePrimary);
      border: 1px solid var(--colorThemePrimary);
      color: white;
    }`,
    `.bold {
      font-weight: bold;
      color: var(--colorThemePrimary);
    }`,
    `.icon-prev-next {
      color: var(--colorThemePrimary);
    }`,
    `.badge-category-icon {
      color: var(--colorThemePrimary);
    }`
  ]
})
export class MainStoreComponent implements OnInit, OnDestroy {

  public terminoDeBuscar = false;
  public products = [];
  // public categories = [];
  // public categories2 = [];
  public numPerPage = 9;
  public totalPages = 1;
  public pages = [];
  public brands = [];
  public paginaSeleccionada = 1;
  public mostrarFiltros = false;
  public category: string;

  // NUEVAS VARIABLES
  public searchQuery = null;
  public sortOption = {
    sort_by: 'updated_at',
    text: 'Mas recientes',
    order_by: 'desc'
  }
  public sortOptions = [
    {
      sort_by: 'updated_at',
      text: 'Mas recientes',
      order_by: 'desc'
    },
    {
      sort_by: 'discount',
      text: 'Mayor descuento',
      order_by: 'desc'
    },
    {
      sort_by: 'discount',
      text: 'Menor descuento',
      order_by: 'asc'
    }
  ];
  public current_page = 1;
  public per_page = 12;
  public total_pages = 0;
  public total_pagesArray = [];
  public prev_page = null;
  public next_page = null;
  public total_products = 0;
  public category_selected = {
    uid: '',
    name: '',
    attribute_types: '',
    variations_type: '',
    path_from_root: [],
    children_categories: [],
  }
  public department_selected = {
    uid: '',
    slug: '',
    name: ''
  };
  public departments = [];
  modalOptions: NgbModalOptions;
  closeResult: string;
  public ImageSrc : string = '';
  public colorTheme = '';
  public colorTheme2 = '#333';
  // private colorDefault = '#0097db';
  private configWorker: any;
  public querySearch = '';
  public querySearch2 = '';
  private typeSearch = 'title';
  public colorSearch = '#797979';
  public productsSearch = [];
  public showPages = false;
  private infoService = null as any;



  constructor(private service: CustomStoreService, private modalService: NgbModal, public activeModal: NgbActiveModal, private workerService: ConsumeWorkersService, private styling: StyleManagerService, private route: ActivatedRoute, private toastrService: ToastrService, private productService: ProductServiceService, private router: Router, private api: RestApiServiceService, private notify: ToastrService, private titleService: Title, private auth: ShopsAuthAngularService) {
    this.initWorkers();
    this.workerService.initWorkers();
    this.modalOptions = {
      size: 'lg',
      centered: true,
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
  }

  ngOnInit() {
    window.scroll(0,0);
    this.infoService = this.service.infoService.getValue();
    if(!this.infoService){
      setTimeout(()=> {
        this.infoService = this.service.infoService.getValue();
      }, 500);
    }
    const currentPage = sessionStorage.getItem('c3RvcmVDdXJyZW50UGFnZQ==');
    if(currentPage){
      if(!isNaN(Number(currentPage))){
        this.current_page = Number(currentPage);
        sessionStorage.removeItem('c3RvcmVDdXJyZW50UGFnZQ==');
      }
    }
    // BUSCAR VERSION
    const currentVersion = localStorage.getItem('Y3VycmVudFZlcnNpb24=');
    if(currentVersion !== null){
      if(currentVersion !== environment.app_version){
        localStorage.setItem('Y3VycmVudFZlcnNpb24=', environment.app_version);
        window.location.reload();
      }
    } else {
      localStorage.setItem('Y3VycmVudFZlcnNpb24=', environment.app_version);
      window.location.reload();
    }
    this.workerService.listenWorkerResponse();
    // this.workerService.finalizarBusqueda(false);
    this.workerService.currentItems.subscribe((data) => {
      if(data !== null){
        if(data.query === 'getItemsV2'){
          let producto = data;
          producto = {
            docs: data.docs,
            total: data.total,
            total_pages: data.total_pages,
            actual_page: data.actual_page,
            prev: data.prev,
            next: data.next,
          }
          if(data.info_category !== undefined){
            this.category_selected = data.info_category;
          }
          producto.sort = this.sortOption;
          producto.collection = this.department_selected;
          producto.category = this.category_selected;
          localStorage.setItem('bGlzdE9mSXRlbXNPbk1haW5TdG9yZQ==', btoa(unescape(encodeURIComponent(JSON.stringify(producto)))));
          this.products = data.docs;
          // this.terminoDeBuscar = true;
          this.current_page = data.actual_page;
          this.total_pages = data.total_pages;
          this.total_pagesArray = Array(this.total_pages).fill(this.total_pages);
          this.prev_page = data.prev;
          this.next_page = data.next;
          this.total_products = data.total;
          this.notify.clear();
          // this.workerService.finalizarBusqueda(true);
          // this.workerService.getWorkerInfo({request: 'getItemsV2'}, true);
        } else if(data.query === 'searchItems'){
          this.products = data.items;
          // this.terminoDeBuscar = true;
          this.total_pages = 0;
          this.total_products = data.items.length;
          this.workerService.getWorkerInfo({request: 'searchItems', token: this.auth.getToken()}, true);
        }

        const peticiones = this.workerService.waitingRequests.getValue();
        if(peticiones.length === 0){
          this.workerService.finalizarBusqueda();
        } else {
          this.products = [];
          this.workerService.finalizarBusqueda(false);
        }
      }
    });

    this.workerService.currentBuscando.subscribe((buscando) => {
      this.terminoDeBuscar = buscando;
    })
    this.configWorker.onmessage = ({data}) => {
      const estado = data.code;
      const result = data.result;
      const items = data.items;
      const general = data.general;
      if(estado === 200){
        let titulo = atob(localStorage.getItem('SU5GTy1USVRMRQ=='));
        let titleHeader = '';
        if(titulo !== undefined){
          titleHeader = `${titulo} | Tienda`;
        } else {
          titleHeader = `LocLog Shops | Tienda`;
        }
        this.titleService.setTitle(titleHeader);
        this.api.updateTags([
          {name: 'og:title', content: titleHeader}
        ]);
        if(result !== undefined && this.querySearch === ''){
          let producto = result;
          producto = {
            docs: result.docs,
            total: result.total,
            total_pages: result.total_pages,
            actual_page: result.actual_page,
            prev: result.prev,
            next: result.next,
          }
          if(result.info_category !== undefined){
            this.category_selected = result.info_category;
          }
          producto.sort = this.sortOption;
          producto.collection = this.department_selected;
          producto.category = this.category_selected;
          localStorage.setItem('bGlzdE9mSXRlbXNPbk1haW5TdG9yZQ==', btoa(unescape(encodeURIComponent(JSON.stringify(producto)))));
          this.products = result.docs;
          this.terminoDeBuscar = true;
          this.current_page = result.actual_page;
          this.total_pages = result.total_pages;
          this.total_pagesArray = Array(this.total_pages).fill(this.total_pages);
          this.prev_page = result.prev;
          this.next_page = result.next;
          this.total_products = result.total;
          this.notify.clear();
          // this.workerService.getWorkerInfo({request: 'searchItems', token: this.auth.getToken()}, true);

        } else if(items !== undefined && this.querySearch !== ''){
          // this.products = items;
          this.terminoDeBuscar = true;
          this.total_pages = 0;
          this.total_products = items.length;
          this.workerService.getWorkerInfo({request: 'searchItems', token: this.auth.getToken()}, true);
        }
      } else {
        if(estado === 402){
          sessionStorage.removeItem('U2VydmljZVN0YXR1cw==');
          this.toastrService.warning('Servicio suspendido');
          this.router.navigate(['', '402']);
        } else if(estado === 403){
          sessionStorage.removeItem('U2VydmljZVN0YXR1cw==');
          this.toastrService.warning('Servicio cancelado');
          this.router.navigate(['', '403']);
        } else if(estado === 429){
          sessionStorage.removeItem('U2VydmljZVN0YXR1cw==');
          this.toastrService.info('Demasiadas solicitudes');
        } else {
          sessionStorage.removeItem('U2VydmljZVN0YXR1cw==');
          this.toastrService.error('Error inesperado :(');
        }
      }
    };
    this.route.queryParams.subscribe(queryParams => {
      const searchQuery = sessionStorage.getItem('c2VhcmNoUXVlcnk=');
      if(queryParams.search !== undefined){
          this.products = [];
        if(queryParams.search !== ''){
          if(searchQuery !== null){
            this.querySearch = JSON.parse(searchQuery).query;
            this.typeSearch = JSON.parse(searchQuery).type;
          } else {
            this.querySearch = queryParams.search;
            this.typeSearch = 'title';
          }
          this.terminoDeBuscar = false;
          if(this.infoService){
            this.searchProducts(this.querySearch);
          } else {
            setTimeout(()=> {
              this.infoService = this.service.infoService.getValue();
              if(this.infoService){
                this.searchProducts(this.querySearch);
              } else {
                setTimeout(()=> {
                  this.infoService = this.service.infoService.getValue();
                  if(this.infoService){
                    this.searchProducts(this.querySearch);
                  } else {
                    setTimeout(()=> {
                      this.infoService = this.service.infoService.getValue();
                      if(this.infoService){
                        this.searchProducts(this.querySearch);
                      } else {
                        setTimeout(()=> {
                          this.infoService = this.service.infoService.getValue();
                          this.searchProducts(this.querySearch);
                        }, 3000);
                      }
                    }, 2000);
                  }
                }, 1000);
              }
            }, 500);
          }
        }
      }
    });
    if(this.querySearch === ''){
      if(this.infoService){
        this.getProducts('',this.department_selected.slug,this.searchQuery,{sort_by: this.sortOption.sort_by, order_by: this.sortOption.order_by},this.current_page, this.per_page);
      } else {
        setTimeout(()=> {
          this.infoService = this.service.infoService.getValue();
          if(this.infoService){
            this.getProducts('',this.department_selected.slug,this.searchQuery,{sort_by: this.sortOption.sort_by, order_by: this.sortOption.order_by},this.current_page, this.per_page);
          } else {
            setTimeout(()=> {
              this.infoService = this.service.infoService.getValue();
              if(this.infoService){
                this.getProducts('',this.department_selected.slug,this.searchQuery,{sort_by: this.sortOption.sort_by, order_by: this.sortOption.order_by},this.current_page, this.per_page);
              } else {
                setTimeout(()=> {
                  this.infoService = this.service.infoService.getValue();
                  if(this.infoService){
                    this.getProducts('',this.department_selected.slug,this.searchQuery,{sort_by: this.sortOption.sort_by, order_by: this.sortOption.order_by},this.current_page, this.per_page);
                  } else {
                    setTimeout(()=> {
                      this.infoService = this.service.infoService.getValue();
                      this.getProducts('',this.department_selected.slug,this.searchQuery,{sort_by: this.sortOption.sort_by, order_by: this.sortOption.order_by},this.current_page, this.per_page);
                    }, 3000);
                  }
                }, 2000);
              }
            }, 1000);
          }
        }, 500);
      }
    }
    this.checkLocalStorage();
  }

  escucharValoresInput(event, idInput){
    const valor = event.target.value;
    this.querySearch2 = valor;
    this.typeSearch = 'title';
  }

  searchByQuery(){
    this.toastrService.clear();
    if(this.querySearch2.trim() !== ''){
      this.toastrService.info('Buscando productos...');
      this.terminoDeBuscar = false;
      this.products = [];
      this.querySearch = this.querySearch2;
      this.searchProducts(this.querySearch);
      // sessionStorage.setItem('c2VhcmNoUXVlcnk=', JSON.stringify({type: environment.searchType, query: this.querySearch}));
      // this.router.navigate(['/tienda'], { queryParams: { search: this.querySearch }, queryParamsHandling: 'merge' });
      // this.showSearch = false;
      // this.querySearch = '';
      // sessionStorage.removeItem('c2VhcmNoUXVlcnk=');
    } else {
      this.toastrService.warning('Ingrese un valor de búsqueda', 'No válido');
    }
  }


  ngOnDestroy() {
    // LIMPIAR HISTORIAL BUSQUEDA TIENDA
    localStorage.removeItem('bGlzdE9mSXRlbXNPbk1haW5TdG9yZQ==');
    localStorage.removeItem('c2VhcmNoUXVlcnk=');
  }

  initWorkers(){
    if(typeof Worker !== undefined){
      // CREAR WORKER
      if(!this.configWorker){
        this.configWorker = new Worker(new URL('../../../workers/config-worker.worker', import.meta.url), { type: 'module'});
      }
    } else {
      // WEBWORKER NOT SUPORT
    }
  }

  isHover(hover, id, type){
    if(hover){
      this.styling.setColor(id, 'color', '#ffffff');
      this.styling.setColor(id, 'background', this.colorTheme);
      this.styling.setColor(id, 'borderColor', this.colorTheme);
    } else {
      if(type === '1'){
        this.styling.setColor(id, 'backgroundColor', 'transparent');
      } else {
        this.styling.setColor(id, 'backgroundColor', '#ffffff');
      }
      this.styling.setColor(id, 'color', '#6c757d');
      this.styling.setColor(id, 'borderColor', '#6c757d');
    }
  }

  checkLocalStorage(){
    this.service.currentService.subscribe((response) => {
      if(response){
        this.colorTheme = response.color;
        this.colorTheme2 = response.color;
        if(response.collections && response.collections.length > 0){
          this.departments = response.collections;
        }
        this.colorSearch = this.colorTheme;
        const titleHeader = response.title ? `${response.title} | Tienda`:'LocLog Shops | Tienda';
        this.titleService.setTitle(titleHeader);
        this.api.updateTags([
          {name: 'og:title', content: titleHeader}
        ]);
      }
    });
    // const colorTheme = atob(localStorage.getItem('SU5GTy1DT0xPUg=='));
    // if(colorTheme !== ''){
    //   this.colorTheme = colorTheme;
    //   this.colorTheme2 = colorTheme;

    // }


    // try {
    //   const departments = JSON.parse(localStorage.getItem('aW5mb0NvbGxlY3Rpb25zU2VydmljZQ=='));
    //   if(departments.length > 0){
    //     this.departments = departments;
    //   }
    // } catch (error) {

    // }

    this.route.queryParams.subscribe(queryParams => {
      if(queryParams.collection !== undefined){
        if(queryParams.collection !== ''){
          const collectionFiltered = this.departments.filter((collectionD, index) => {
            collectionD.index = index;
            return collectionD.slug.trim() === queryParams.collection.trim();
          });
          if(collectionFiltered.length > 0){
            this.department_selected = collectionFiltered[0];
          }
        }
      }
    });
  }

  open(content) {
    this.modalService.open(content, this.modalOptions).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  // listenQuantity(event, producto){
  //   const value = event.target.value;
  //   const reg = /^\d+$/;
  //   if(reg.test(value)){
  //     const quantity = Number(value);
  //     let stock = producto.stock;
  //     if(quantity > 0 && quantity <= stock){
  //       producto.counter = quantity;
  //     } else if(quantity > stock){
  //       producto.counter = stock;
  //       event.target.value = `${stock}`;
  //     } else {
  //       producto.counter = 1;
  //       event.target.value = '';
  //     }
  //   } else {
  //     producto.counter = 1;
  //     event.target.value = '';
  //   }
  // }

  // actionInCart(quantity, type, producto){
  //   if(type === 'less' && (producto.counter - quantity) >= 1){
  //     producto.counter = producto.counter - quantity;
  //   }
  //   if(type === 'more' && (producto.counter + quantity) <= producto.stock){
  //     producto.counter = producto.counter + quantity;
  //   }
  // }

  // showPreorderModal(product){
  //   this.activeModal.close('Modal Closed');
  //   const modalRef = this.modalService.open(PreorderModalComponent, {size: 'lg', centered: true});
  //   // modalRef.componentInstance.product = product;
  //   modalRef.componentInstance.product = {
  //     ...product,
  //     ...{
  //       source: 'main'
  //     }
  //   };

  // }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  navOnPagination(type){
    if(this.terminoDeBuscar){
      this.terminoDeBuscar = false;
      if(type === 'next'){
        this.products = [];
        window.scroll(0,0);
        if(this.category_selected.children_categories.length === 0){
          this.getProducts(this.category_selected.uid, this.department_selected.slug, this.searchQuery, {sort_by: this.sortOption.sort_by, order_by: this.sortOption.order_by}, this.current_page + 1, this.per_page);
        } else {
          this.getProducts('', this.department_selected.slug, this.searchQuery, {sort_by: this.sortOption.sort_by, order_by: this.sortOption.order_by}, this.current_page + 1, this.per_page);
        }
      } else if(type === 'back'){
        this.products = [];
        window.scroll(0,0);
        if(this.category_selected.children_categories.length === 0){
          this.getProducts(this.category_selected.uid, this.department_selected.slug, this.searchQuery, {sort_by: this.sortOption.sort_by, order_by: this.sortOption.order_by}, this.current_page - 1, this.per_page);
        } else {
          this.getProducts('', this.department_selected.slug, this.searchQuery, {sort_by: this.sortOption.sort_by, order_by: this.sortOption.order_by}, this.current_page - 1, this.per_page);
        }
      }
    } else {
      this.notify.warning('Por favor espere...');
    }
  }

  cambiarPagina(page){
    if(page !== this.current_page){
      this.terminoDeBuscar = false;
      window.scroll(0,0);
      this.products = [];
      this.showPages = false;
      this.getProducts(this.category_selected.uid, this.department_selected.slug, this.searchQuery, {sort_by: this.sortOption.sort_by, order_by: this.sortOption.order_by}, page, this.per_page);
    }
  }

  showPageSearch(){
    this.showPages = true;
  }

  updateUrl(position, product) {
    if(product.attribute_types === 'attributes'){
      if(this.products[position].images[0] !== undefined){
        this.products[position].images[0].url = 'assets/images/product_img1.jpg';
      } else {
        this.products[position].images = [{url: 'assets/images/product_img1.jpg'}];
      }
    } else if(product.attribute_types === 'variations'){
      this.products[position].variations[0].images[0].url = 'assets/images/product_img1.jpg';
    }
  }

  selectSort(sort){
    if(sort.text !== this.sortOption.text){
      this.sortOption = sort;
      this.notify.info('Un momento...');
      this.getProducts(this.category_selected.uid, this.department_selected.slug, this.searchQuery, {sort_by: this.sortOption.sort_by, order_by: this.sortOption.order_by}, 1, this.per_page);
    }
  }

  selectCollection(collection, position){
    this.terminoDeBuscar = false;
    this.products = [];
    if(this.department_selected.slug !== collection.slug){
      this.department_selected = collection;
      this.router.navigate(['', 'tienda'], { queryParams: { collection: collection.slug }, queryParamsHandling: 'merge' });
      if(this.category_selected.children_categories.length === 0){
        this.getProducts(this.category_selected.uid, this.department_selected.slug, this.searchQuery, {sort_by: this.sortOption.sort_by, order_by: this.sortOption.order_by}, 1, this.per_page);
      } else {
        this.getProducts('', this.department_selected.slug, this.searchQuery, {sort_by: this.sortOption.sort_by, order_by: this.sortOption.order_by}, 1, this.per_page);
      }
      this.styling.setColor(`${position}`, 'backgroundColor', this.colorTheme);
    } else {
      this.department_selected = {
        uid: '',
        name: '',
        slug: ''
      }
      this.router.navigate(['', 'tienda'], { queryParams: { collection: undefined }, queryParamsHandling: 'merge' });
      if(this.category_selected.children_categories.length === 0){
        this.getProducts(this.category_selected.uid, this.department_selected.slug, this.searchQuery, {sort_by: this.sortOption.sort_by, order_by: this.sortOption.order_by}, 1, this.per_page);
      } else {
        this.getProducts('', this.department_selected.slug, this.searchQuery, {sort_by: this.sortOption.sort_by, order_by: this.sortOption.order_by}, 1, this.per_page);
      }
      this.styling.setColor(`${position}`, 'backgroundColor', 'transparent');
    }
  }


  getAttributes(items_id){
    let estado2;
      let attributes;
      this.api.getAttributesItem(items_id, this.auth.getToken(), this.infoService ? this.infoService.pickerpro_id: '').subscribe(respuesta => {
        attributes = JSON.parse(JSON.stringify(respuesta)).attributes;
        estado2 = JSON.parse(JSON.stringify(respuesta)).code;
      },  (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
        } else {
        }
        if (err.status === 400) {
          this.toastrService.error('Error 400', err.error.message);
        }
        if (err.status === 401) {
          this.toastrService.warning('No estás autorizado para acceder');
          this.router.navigate(['', 'ingresar']);
        }
        if (err.status === 403) {
          this.toastrService.error('El CEDI asignado no existe o no está activo');
        }
        if (err.status === 404) {
          this.toastrService.error('Error 404', err.error.message);
        }
        if (err.status === 429) {
          this.toastrService.warning(err.error.message);
        }
        if (err.status === 500) {
          this.toastrService.error('Error 500', err.error.message);
        }
      }, () => {
        if(estado2 === 200){
          try {
            attributes.forEach((attribute) => {
              const findProduct = this.products.findIndex((product)=> product.id === attribute.item_id);
              if(findProduct !== -1){
                if(attribute.attributes.length > 0){
                  attribute.attributes.forEach((attr) => {
                    this.products[findProduct].attributes.push(attr);
                  });
                }
              }
            });
          } catch (error) {

          }
        }
      });
  }

  getProducts(categoryId, genderType, queryInfo, sortInfo, currentPage, perPage){
    let estado2;
      let result;
      try {
        sessionStorage.removeItem('c2VhcmNoX2lk');
      } catch (error) {

      }
      if(this.infoService){
        this.api.getItemsV2(categoryId, genderType, queryInfo, sortInfo, currentPage, perPage, this.auth.getToken(), this.infoService ? this.infoService.pickerpro_id: '').subscribe(respuesta => {
          result = JSON.parse(JSON.stringify(respuesta)).result;
          estado2 = JSON.parse(JSON.stringify(respuesta)).code;
        },  (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
          } else {
          }
          if (err.status === 400) {
            this.toastrService.error('Error 400');
          }
          if (err.status === 401) {
            this.toastrService.warning('No estás autorizado para acceder');
            this.router.navigate(['', 'ingresar']);
          }
          if (err.status === 429) {
            this.toastrService.warning(err.error.message);
          }
          if (err.status === 500) {
            this.toastrService.error('Error 500');
          }
        }, () => {
          if(estado2 === 200){
            let producto = result;
            producto.sort = this.sortOption;
            producto.collection = this.department_selected;
            producto.category = this.category_selected;
            localStorage.setItem('bGlzdE9mSXRlbXNPbk1haW5TdG9yZQ==', btoa(unescape(encodeURIComponent(JSON.stringify(producto)))));
            // this.products = result.docs;
            this.products = result.docs.map((item) => {
              // let producto = item;
              // producto.stock = 0;
              return {
                ...item,
                ...{
                  counter: 1
                }
              }
            });
            this.terminoDeBuscar = true;
            this.current_page = result.actual_page;
            this.total_pages = result.total_pages;
            this.total_pagesArray = Array(this.total_pages).fill(this.total_pages);
            this.prev_page = result.prev;
            this.next_page = result.next;
            this.total_products = result.total;
            this.notify.clear();
            const allIds = this.products.map((product) => product.id);
            this.getAttributes(allIds);
            const CediItemsId = this.products.filter((product) => product.warning === '').map((product) => product.id);
            if(CediItemsId.length > 0){
              this.getPrices(CediItemsId);
              this.getStocks(CediItemsId);
            }
            let AnotherItemsId = this.products.filter((product) => product.warning !== '');
            if(AnotherItemsId.length > 0 && environment.external_wms){
              const wmsId = AnotherItemsId[0].wid;
              AnotherItemsId = AnotherItemsId.map((item) => item.id);
              this.getExternalWMS(AnotherItemsId, wmsId);
            }
            this.terminoDeBuscar = true;
          }
        });
      } else {
        setTimeout(()=> {
          this.api.getItemsV2(categoryId, genderType, queryInfo, sortInfo, currentPage, perPage, this.auth.getToken(), this.infoService ? this.infoService.pickerpro_id: '').subscribe(respuesta => {
            result = JSON.parse(JSON.stringify(respuesta)).result;
            estado2 = JSON.parse(JSON.stringify(respuesta)).code;
          },  (err: HttpErrorResponse) => {
            if (err.error instanceof Error) {
            } else {
            }
            if (err.status === 400) {
              this.toastrService.error('Error 400');
            }
            if (err.status === 401) {
              this.toastrService.warning('No estás autorizado para acceder');
              this.router.navigate(['', 'ingresar']);
            }
            if (err.status === 429) {
              this.toastrService.warning(err.error.message);
            }
            if (err.status === 500) {
              this.toastrService.error('Error 500');
            }
          }, () => {
            if(estado2 === 200){
              let producto = result;
              producto.sort = this.sortOption;
              producto.collection = this.department_selected;
              producto.category = this.category_selected;
              localStorage.setItem('bGlzdE9mSXRlbXNPbk1haW5TdG9yZQ==', btoa(unescape(encodeURIComponent(JSON.stringify(producto)))));
              // this.products = result.docs;
              this.products = result.docs.map((item) => {
                // let producto = item;
                // producto.stock = 0;
                return {
                  ...item,
                  ...{
                    counter: 1
                  }
                }
              });
              this.terminoDeBuscar = true;
              this.current_page = result.actual_page;
              this.total_pages = result.total_pages;
              this.total_pagesArray = Array(this.total_pages).fill(this.total_pages);
              this.prev_page = result.prev;
              this.next_page = result.next;
              this.total_products = result.total;
              this.notify.clear();
              const allIds = this.products.map((product) => product.id);
              this.getAttributes(allIds);
              const CediItemsId = this.products.filter((product) => product.warning === '').map((product) => product.id);
              if(CediItemsId.length > 0){
                this.getPrices(CediItemsId);
                this.getStocks(CediItemsId);
              }
              let AnotherItemsId = this.products.filter((product) => product.warning !== '');
              if(AnotherItemsId.length > 0 && environment.external_wms){
                const wmsId = AnotherItemsId[0].wid;
                AnotherItemsId = AnotherItemsId.map((item) => item.id);
                this.getExternalWMS(AnotherItemsId, wmsId);
              }
              this.terminoDeBuscar = true;
            }
          });
        }, 550);
      }
  }

  searchProducts(querySearch){
    let estado2;
      let items;
      let search_id;
      this.api.searchWmsItems(querySearch, this.auth.getToken(), this.infoService.pickerpro_id ? this.infoService.pickerpro_id : '').subscribe(respuesta => {
        items = JSON.parse(JSON.stringify(respuesta)).items;
        search_id = JSON.parse(JSON.stringify(respuesta)).search_id;
        estado2 = JSON.parse(JSON.stringify(respuesta)).code;
      },  (err: HttpErrorResponse) => {
        this.toastrService.clear();
        if (err.error instanceof Error) {
        } else {
        }
        if (err.status === 400) {
          this.toastrService.error('Error 400');
        }
        if (err.status === 401) {
          this.toastrService.warning('No estás autorizado para acceder');
          this.router.navigate(['', 'ingresar']);
        }
        if (err.status === 429) {
          this.toastrService.warning(err.error.message);
        }
        if (err.status === 500) {
          this.toastrService.error('Error 500');
        }
      }, () => {
        this.toastrService.clear();
        if(estado2 === 200){
          this.products = items.map((item) => {
            return {
              ...item,
              ...{
                counter: 1
              }
            }
          });
          sessionStorage.setItem('c2VhcmNoX2lk', search_id);
          this.terminoDeBuscar = true;
          this.total_pages = 0;
          this.total_products = items.length;
          const CediItemsId = this.products.filter((product) => product.warning === '').map((product) => product.id);
          if(CediItemsId.length > 0){
            this.getPrices(CediItemsId);
            this.getStocks(CediItemsId);
          }
          let AnotherItemsId = this.products.filter((product) => product.warning !== '');
          if(AnotherItemsId.length > 0 && environment.external_wms){
            const wmsId = AnotherItemsId[0].wid;
            AnotherItemsId = AnotherItemsId.map((item) => item.id);
            this.getExternalWMS(AnotherItemsId, wmsId);
          }
        }
      });
  }

  getPrices(items_id){
    let estado2;
      let prices;
      this.api.getPricesItems(items_id, this.auth.getToken(), this.infoService ? this.infoService.pickerpro_id: '').subscribe(respuesta => {
        prices = JSON.parse(JSON.stringify(respuesta)).prices;
        estado2 = JSON.parse(JSON.stringify(respuesta)).code;
      },  (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
        } else {
        }
        if (err.status === 400) {
          this.toastrService.error('Error 400', err.error.message);
        }
        if (err.status === 401) {
          this.toastrService.warning('No estás autorizado para acceder');
          this.router.navigate(['', 'ingresar']);
        }
        if (err.status === 403) {
          this.toastrService.error('El CEDI asignado no existe o no está activo');
        }
        if (err.status === 404) {
          this.toastrService.error('Error 404', err.error.message);
        }
        if (err.status === 429) {
          this.toastrService.warning(err.error.message);
        }
        if (err.status === 500) {
          this.toastrService.error('Error 500', err.error.message);
        }
      }, () => {
        if(estado2 === 200){
          try {
            prices.forEach((price) => {
              const indexItem = this.products.findIndex((item) => item.id === price.item_id);
              if(indexItem !== -1){
                this.products[indexItem].discount = price.discount;
                this.products[indexItem].retail_price = price.retail_price;
                this.products[indexItem].currency = 'MXN';
                if(price.discount > 0){
                  this.products[indexItem].has_discount = true;
                } else {
                  this.products[indexItem].has_discount = false;
                  this.products[indexItem].minimum_purchase = 0;
                  this.products[indexItem].wholesale_price = price.retail_price;
                }
                if(price.has_wholesale_price){
                  this.products[indexItem].has_wholesale_price = true;
                } else {
                  this.products[indexItem].has_wholesale_price = false;
                }
              }
            });
            // this.productService.actualizarListado(this.products);
          } catch (error) {

          }
        }
      });
  }

  getStocks(items_id){
    const searchId = sessionStorage.getItem('c2VhcmNoX2lk');
    const search_id = searchId ? searchId : '';
    let estado2;
      let stocks;
      this.api.getStockItems(items_id, this.auth.getToken(), this.infoService ? this.infoService.pickerpro_id: '', search_id).subscribe(respuesta => {
        stocks = JSON.parse(JSON.stringify(respuesta)).stocks;
        estado2 = JSON.parse(JSON.stringify(respuesta)).code;
      },  (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
        } else {
        }
        if (err.status === 400) {
          this.toastrService.error('Error 400', err.error.message);
        }
        if (err.status === 401) {
          this.toastrService.warning('No estás autorizado para acceder');
          this.router.navigate(['', 'ingresar']);
        }
        if (err.status === 403) {
          this.toastrService.error('El CEDI asignado no existe o no está activo');
        }
        if (err.status === 404) {
          this.toastrService.error('Error 404', err.error.message);
        }
        if (err.status === 429) {
          this.toastrService.warning(err.error.message);
        }
        if (err.status === 500) {
          this.toastrService.error('Error 500', err.error.message);
        }
      }, () => {
        if(estado2 === 200){
          try {
            let withoutStock = [];
            stocks.forEach((stock) => {
              const indexItem = this.products.findIndex((item) => item.id === stock.item_id);
              if(indexItem !== -1){
                this.products[indexItem].stock+= stock.qty;
              }
            });
            items_id.forEach((item) => {
              const indexItem = this.products.findIndex((item2) => item2.id === item);
              if(indexItem !== -1){
                if(this.products[indexItem].stock === null){
                  // this.products[indexItem].stock = 0;
                  this.products[indexItem].retail_price = null;
                  this.products[indexItem].discount = null;
                  withoutStock.push(this.products[indexItem]);
                }
              }
            });
            if(withoutStock.length > 0 && environment.external_wms){
              const barcodes = withoutStock.map((item) => item.gtin);
              this.getExternalWMS(barcodes, 'cm9zYXJpbw==', true);
            }
            // 1. hacer peticion precios y stock rosario por codigo de barras con wid de cm9zYXJpbw== y is_barcode = true
            // 2. checar items de cada stock y actualizarlo y actualizar el id y wid en caso de que tenga stock > 0
          } catch (error) {

          }
        }
      });
  }

  getExternalWMS(items_id, wmsId, barcodes = false){
    const searchId = sessionStorage.getItem('c2VhcmNoX2lk');
    const search_id = searchId ? searchId : '';
    let estado2;
      let stocks;
      this.api.getExternalWMS(items_id, wmsId, this.auth.getToken(), barcodes, this.infoService ? this.infoService.pickerpro_id: '', search_id).subscribe(respuesta => {
        stocks = JSON.parse(JSON.stringify(respuesta)).stocks;
        estado2 = JSON.parse(JSON.stringify(respuesta)).code;
      },  (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
        } else {
        }
        if (err.status === 400) {
          this.toastrService.error('Error 400', err.error.message);
        }
        if (err.status === 401) {
          this.toastrService.warning('No estás autorizado para acceder');
          this.router.navigate(['', 'ingresar']);
        }
        if (err.status === 403) {
          this.toastrService.error('El CEDI asignado no existe o no está activo');
        }
        if (err.status === 404) {
          // this.toastrService.error('Error 404', err.error.message);
        }
        if (err.status === 429) {
          this.toastrService.warning(err.error.message);
        }
        if (err.status === 500) {
          this.toastrService.error('Error 500', err.error.message);
        }
      }, () => {
        if(estado2 === 200){
          stocks.forEach((price) => {
            let indexItem = -1;
            if(!barcodes){
              indexItem = this.products.findIndex((item) => item.id === price.item_id);
            } else {
              indexItem = this.products.findIndex((item) => item.gtin === price.gtin);
            }
            if(indexItem !== -1){
              if(price.qty > 0){
                if(barcodes){
                  this.products[indexItem].id = price.item_id;
                  this.products[indexItem].wid = wmsId;
                }
                this.products[indexItem].discount = price.discount;
                this.products[indexItem].retail_price = price.retail_price;
                this.products[indexItem].currency = 'MXN';
                this.products[indexItem].stock = price.qty;
              } else {
                this.products[indexItem].stock = 0;
              }
              if(price.discount > 0){
                this.products[indexItem].has_discount = true;
              } else {
                this.products[indexItem].has_discount = false;
                this.products[indexItem].minimum_purchase = 0;
                this.products[indexItem].wholesale_price = price.retail_price;
              }
              if(price.has_wholesale_price){
                this.products[indexItem].has_wholesale_price = true;
              } else {
                this.products[indexItem].has_wholesale_price = false;
              }
            }
          });

          items_id.forEach((item) => {
            let indexItem = -1;
            if(!barcodes){
              indexItem = this.products.findIndex((item2) => item2.id === item);
            } else {
              indexItem = this.products.findIndex((item2) => item2.gtin === item);
            }
            if(indexItem !== -1){
              if(this.products[indexItem].stock === null){
                this.products[indexItem].stock = 0;
              }
            }
          });


          // this.productService.actualizarListado(this.products);
          // try {
          //   stocks.forEach((stock) => {
          //     const indexItem = this.products.findIndex((item) => item.id === stock.item_id);
          //     if(indexItem !== -1){
          //       this.products[indexItem].stock = stock.qty;
          //     }
          //   });
          // } catch (error) {

          // }
        }
      });
  }

  cleanSearch(){
    this.products = [];
    sessionStorage.removeItem('c2VhcmNoUXVlcnk=');
    sessionStorage.removeItem('c2VhcmNoX2lk');
    this.querySearch = '';
    // this.cart.updateQuerySearch(this.querySearch);
    this.terminoDeBuscar = false;
    this.checkLocalStorage();
    this.getProducts('',this.department_selected.slug,null,{sort_by: this.sortOption.sort_by, order_by: this.sortOption.order_by},this.current_page, this.per_page);
  }

  scroll(id){
    setTimeout(() => {
      try {
        this.styling.setEffect(id, 'focus');
        this.styling.setEffect(id, 'scrollIntoView', {block: "start", behavior: "smooth"});
      } catch (error) {

      }
    }, 100);
  }

  showProduct(producto){
    sessionStorage.setItem('c3RvcmVDdXJyZW50UGFnZQ==', `${this.current_page}`);
    sessionStorage.setItem('c291cmNlQmFjaw==', 'main-store');
    this.productService.actualizarDetalleProducto(producto);
    this.router.navigate(['', 'p', producto.slug], { queryParams: { uid: producto.id } });
  }

  showQuickView(product){
    const modalRef = this.modalService.open(QuickViewComponent, {size: 'lg', centered: true});
    modalRef.componentInstance.product = product;
  }

  showCartModal(product){
    const modalRef = this.modalService.open(CartModalComponent, {size: 'lg', centered: true});
    modalRef.componentInstance.product = product;
  }

}
